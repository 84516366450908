// Please sort translations alphabetically, and group game translations
export default {
  aboutTheGameTitle: 'About the Game',
  accept: 'Accept',
  actionError: 'Action error',
  actionSuccess: 'Action success',
  activePromotionError: 'Promotion is currently active',
  addSymbol: 'Add Symbol',
  advanced: 'Advanced',
  autoplay: 'Autoplay',
  back: 'Back',
  balance: 'Balance',
  baseWinAmount: 'Base win amount',
  bet: 'Bet',
  betAmount: 'Bet Amount',
  betterLuckNextTimeLabel: 'Better luck next time',
  bonus: 'bonus',
  bonusBuy: 'Bonus Buy',
  bonusBuyDescription: 'A Bonus Buy lets you buy your way into the bonus round by pressing the Store button. Once you\'ve chosen an option, the dialog closes and the next spin triggers the bonus game.',
  bonusDynamicDistribution: 'Three or more symbols anywhere on the reels activate the free spins bonus game. Symbol distribution in free rounds, as the number of free rounds, depends on the bonus symbols that started the bonus game.',
  bonusDynamicDistributionWithPrizeCount: 'Three or more symbols anywhere on the reels activate ${prizeCount} free rounds. Symbol distribution in free rounds depends on the bonus symbols that started the bonus game.',
  bonusDynamicFreeRoundsOnboardingDescription: 'Score big with free spins! Land at least three bonus symbols to activate the bonus game with dynamic multipliers for every spin!',
  bonusFeaturesTitle: 'Bonus features',
  bonusFreeRoundsDescription: '3 or more symbols anywhere on the reels activate 10 free spins bonus game. The multiplier applies to all winnings from free spins bonus game.',
  bonusFreeRoundsOnboardingDescription: '3 or more symbols anywhere on the reels activate 10 free spins bonus game. The multiplier applies to all winnings from free spins bonus game.',
  bonusFreeRoundsStart: 'Click anywhere to start',
  bonusGame: 'Bonus game',
  bonusPickIndex: 'Picked position',
  bonusPickPrizeDescription: '3 or more symbols anywhere on the reels activate Pick Prize bonus game. Select one item to reveal prizes. The higher the number of bonus symbols, the higher the prizes in the bonus game.',
  bonusPickPrizeLevelsDescription: '3 or more symbols anywhere on the reels activate the Prize Adventure bonus game. The game consists of ${numberOfLevels} prize levels. Choose wisely, as each level offers both a prize and a trap. The higher the number of bonus symbols, the higher the prizes in the bonus game.',
  bonusPickPrizeLevelsOnboardingDescription: '3 or more symbols anywhere on the reels activate Prize Adventure bonus game.',
  bonusPickPrizeOnboardingDescription: '3 or more symbols anywhere on the reels activate Pick Prize bonus game.',
  bonusPickWon: 'Prize won',
  bonusSymbol: 'Bonus Symbol',
  bonusSymbolTooltipDescription: '3, 4 or 5 Bonus symbols trigger the Bonus game',
  bonusWinAmount: 'Bonus win amount',
  buyLabel: 'Buy',
  buyOptionLabel: 'Buy option',
  cancel: 'Cancel',
  cashLink: 'Cash link',
  cashLinkBonusGame: 'Cash link bonus game',
  cashSymbol: 'Cash Symbol',
  cashSymbolDescription: 'Cash symbol provides a bet multiplier worth ${minDistributionValue}x - ${maxDistributionValue}x your stake.',
  cashSymbolTooltipDescription: 'The symbol shows the possible cash amount you can claim, once the collect feature is triggered.',
  claim: 'Claim',
  close: 'Close',
  closeGame: 'Game can not be continued. Please close game.',
  collect: 'Collect',
  collectMultiplier: 'Collect multiplier',
  collectSymbol: 'Collect Symbol',
  collectSymbolDescription: 'Collects all cash values on the screen.',
  collectSymbolTooltipDescription: 'The symbol collects all cash values on the screen.',
  collectWinAmount: 'Collect win amount',
  confirm: 'Confirm',
  congratulationMessage: 'Congratulations',
  continue: 'Continue',
  continueGame: 'Click to continue',
  currency: 'Currency',
  dateRange: 'Date range',
  dbReadError: 'Database error',
  dbSaveError: 'Database error',
  demoMode: 'Demo',
  demoStoreTooltip: 'Store is not available in demo mode.',
  dialogBetAmountTitle: 'Bet Amount',
  dialogMenuGameInfoTitle: 'Game Info',
  dialogSettingsLightningLabel: 'Lightning spin',
  dialogSettingsSoundLabel: 'Sound',
  dialogSettingsSpacebarLabel: 'Use spacebar for spin',
  dialogSettingsTitle: 'Settings',
  dialogSettingsTurboLabel: 'Turbo spin',
  dialogStoreTitle: 'Store',
  directWinTitle: 'Direct win',
  dynamicMultiplierSymbolDescription: 'Collect multipliers and boost your winnings! Land at least three bonus symbols and trigger free spins where every spin could land you a multiplier.',
  dynamicMultiplierSymbolTooltipDescription: 'Increases your multiplier while in free spins.',
  endedLabel: 'ended',
  endsInLabel: 'Ends in',
  expandingWildFeature: 'Expanding Wild feature',
  expiredPlayerTokenError: 'Expired player token',
  featureNotAvailableError: 'Feature not available',
  fieldNotFoundError: 'Round validation error',
  fifth: 'Fifth',
  fillPositions: 'Fill positions',
  filterMonth: 'This month',
  filterPreviousMonth: 'Previous month',
  filterToday: 'Today',
  filterWeek: 'This week',
  filterYesterday: 'Yesterday',
  first: 'First',
  fourth: 'Fourth',
  freeRounds: 'Free Rounds',
  freeRoundsMultiplier: 'Free rounds multiplier',
  freeSpins: 'Free Spins',
  fundsAdded: 'Funds have been added to your balance',
  gameConfigNotFoundError: 'Invalid game configuration',
  gameMultipeInstancesError: 'Invalid parameters',
  gameNotFoundError: 'Game not found',
  gameSummary: 'Game Summary',
  genericErrorMessage: 'Something went wrong!',
  history: 'History',
  holdForLightningMode: 'Hold for lightning mode',
  howToPlayBonusLimit: 'If the total win of the bonus game reaches ${limit} times your bet, the round immediately ends, and rest of free spins are not played.',
  howToPlayDescription: 'Select a bet size, then press the Spin button to begin. To use autoplay, press the Autoplay button and select the desired number of rounds. You can stop autoplay mode anytime by clicking the stop button. You can control the spin speed by selecting one of the predefined spin options:\n- Normal spin: This is the default mode.\n- Lighting spin: In the first spin symbols just fall off on the reel, without any lazy animations. You can activate this option in the settings.\n- Lighting mode: To activate this mode press the spin button for 1 second. The lighting spin is activated, and an autoplay with an infinite number of spins is started. You can always stop this mode by clicking the stop button.',
  howToPlayTitle: 'How to Play',
  in: 'In',
  insufficientFundsError: 'Insufficient funds',
  integrationRequestError: 'Integration error',
  integrationRequestServiceError: 'Integration error',
  integrationRequestTimeoutError: 'Integration error',
  integrationRequestUrlError: 'Integration error',
  integrationResponseError: 'Integration error',
  invalidBalance: 'Insufficient funds.',
  invalidDateRANGE: 'Invalid date range',
  invalidGameError: 'Invalid game parameters',
  invalidInputError: 'Invalid input',
  invalidJackpotQueue: 'Jackpot error',
  invalidParamError: 'Invalid parameters',
  invalidPlayerError: 'Invalid player',
  invalidPlayerTokenError: 'Invalid player token',
  invalidPromotionBalance: 'Promotion money left ${amountLeft}, adjust stake to play.',
  invalidRoundError: 'Invalid round',
  invalidSignatureError: 'Invalid signature',
  invalidStateError: 'An error happened',
  invalidTransactionCurrencyError: 'Transaction error',
  invalidTransactionError: 'Transaction error',
  invalidTransactionTypeError: 'Transaction error',
  jackpot: 'Jackpot',
  jackpotGamesNotFoundError: 'Jackpot error',
  jackpotNotFoundError: 'Jackpot error',
  jackpotSymbols: 'Jackpot Symbols',
  jackpotWinAmount: 'Jackpot win amount',
  jackpotWonMessage: 'You have won the jackpot.',
  level: 'Level',
  lightningspin: 'Lightning spin',
  lines: 'Lines',
  lossLimit: 'Loss limit',
  mainFeaturesTitle: 'Main features',
  manualBonus: 'Bonus',
  manualBonusInfoMessage: 'To start free rounds please set bet amount and number of lines. If there is a lack of funds to continue with wanted settings, free rounds will continue with a minimum stake per single line.',
  manualBonusRounds: 'Free spins',
  manualBonusRoundsInfoMessage: 'Congratulations <b> you won ${numberOfSpins} free spins</b>. Bet amount and number of lines are predefined.',
  manualStopPromotionMessage: 'Operator has ended the promotion.',
  maxBet: 'Max bet',
  maxLines: 'Max lines',
  messageToSpin: 'Spin and try your luck',
  multiplier: 'Multiplier',
  multiplierSymbol: 'Multiplier Symbol',
  multiplierSymbolDescription: 'Collect symbols during normal spins and boost your winnings! The collected multiplier amount multiplies each cash symbol and each winline win, once the collect feature is triggered.',
  multiplierSymbolTooltipDescription: 'Increases your collect multiplier.',
  notImplementedError: 'Feature not implemented',
  numOfBonusSymbolsLabel: '${hits} Bonus symbols',
  numOfFreeRoundsLabel: '${hits} Free Rounds',
  onbordingScreenDontShowMessage: 'Don\'t show this again.',
  openBonusError: 'Bonus game is currently open',
  partnerDeactivatedError: 'Game is deactivated',
  partnerSetupError: 'Invalid setup',
  paylines: 'Paylines',
  paytable: 'Paytable',
  paytableBonusFreeRoundsMultiplier: 'Three or more symbols anywhere on the reels activate ${prizeCount} free rounds. The multiplier applies to all winnings from free spins bonus game.',
  paytableProgressDynamicMultiplierSymbolDescription: 'Collect multipliers and boost your winnings! Fill the progress bar and trigger free spins where every spin could land you a multiplier.',
  paytableProgressFreeRounds: 'The symbol increases your progress level. Once you fill the progress bar ${prizeCount} free rounds trigger.  Symbol distribution in free rounds differs from the one in the base game.',
  paytableProgressPickPrize: 'The symbol increases your progress level. Once you fill the progress bar, Pick Prize bonus game triggers. Select one item to reveal prizes.', // eslint-disable-line max-len
  paytableProgressPickPrizeLevels: 'The symbol increases your progress level. Once you fill the progress bar, the Prize Adventure bonus game triggers. The game consists of ${prizeCount} prize levels. Choose wisely, as each level offers both a prize and a trap.', // eslint-disable-line max-len
  paytableProgressSymbolTitle: 'Progress Symbol',
  paytableWildMultiplierSymbolDescription: 'Wild symbol replace any symbol except Bonus. Multiplier can contain values in between ${minValue} and ${maxValue}.',
  platformUnavailableError: 'Platform unavailable',
  playNow: 'Play now',
  potAmountLabel: 'Pot amount',
  pressAnywhereToContinue: 'Tap to continue',
  prizeAdventureBonus: 'Prize adventure bonus',
  prizeDescription: 'The higher the number of bonus symbols, the higher the prize.',
  promotion: 'Promotion',
  promotionDays: 'days',
  promotionDescription: 'You have recived bonus in value of ${amountLeft}',
  promotionEnded: 'Promotion ended',
  promotionFreeRoundsMessage: 'The next ${spinsLeft} rounds will continue with the minimum stake per single line.',
  promotionHours: 'hours',
  promotionMinutes: 'minutes',
  promotionNotImplementedError: 'Invalid promotion parameters',
  promotionSeconds: 'seconds',
  promotionStart: 'Start Free spins',
  promotionYouReceived: 'You received',
  refreshPage: 'Reload game to play.',
  reload: 'Reload',
  resetWarningCancelLabel: 'Cancel',
  resetWarningContinueLabel: 'Continue',
  resetWarningMessageBetAmount: 'If you increase your bet, the multiplier/progress level resets to zero.',
  resetWarningMessageBoldPart: 'resets to zero.',
  resetWarningMessageBoldPartCollect: 'resets to one.',
  resetWarningMessageCollectBetAmount: 'If you increase your bet, the multiplier/ resets to one.',
  resetWarningMessageCollectFeatureBuy: 'If you buy the feature, the multiplier/ resets to one.',
  resetWarningMessageFeatureBuy: 'If you buy the feature, the multiplier/progress level resets to zero.',
  resetWarningMessageProgressBetAmount: 'If you increase your bet, progress level/ resets to zero.',
  resetWarningMessageProgressFeatureBuy: 'If you buy the feature, progress level/ resets to zero.',
  resetWarningTitle: 'Warning!',
  respin: 'Respin',
  round: 'Round',
  roundNotFoundError: 'Round not found',
  roundsInRowLimitError: 'Please, collect the pot amount to keep playing',
  rules: 'Rules',
  second: 'Second',
  settings: 'Settings',
  sound: 'Sound',
  spaceBarToSpinMessage: 'Spacebar spin',
  spin: 'Spin',
  spinningMessage: 'Good luck',
  spins: 'Spins',
  standardSymbolDescription: 'Win 5 to increase stake per line',
  start: 'Start',
  stickyExpandingWildSymbol: 'Sticky Expanding Wild Symbol',
  stickyWildNoMultiplierSymbolDescription: 'Wild symbol replaces any symbol except Bonus symbol. It becomes sticky and doesn\'t change the position until free spins are finished.',
  stickyWildSymbol: 'Sticky Wild Symbol',
  stickyWildWithMultiplierSymbolDescription: 'Wild symbol replaces any symbol except Bonus symbol. It becomes sticky and doesn\'t change the position until free spins are finished. The multiplier on the symbol can go up to ${max}x',
  stopOnAnyWin: 'Stop on any win',
  stopOnBonusGame: 'Stop on a bonus game',
  summary: 'Summary',
  symbol: 'symbol',
  symbolDescription: 'Get 5 to earn up to',
  tenantCurrencyUnsupportedError: 'Unsupported currency',
  tenantDeactivatedError: 'Game or client is deactivated',
  tenantGamesNotFoundError: 'Invalid parameters',
  tenantMismatchError: 'Invalid parameters',
  tenantNotFoundError: 'Invalid parameters',
  third: 'Third',
  ticketsHistoryDemoModeMessage: 'Tickets history not available in Demo mode.',
  ticketsHistoryNoTicketsMessage: 'There is no tickets for selected filter. Try different filters.',
  time: 'Time',
  total: 'Total',
  towersTitle: 'Towers',
  transactionErrorError: 'Transaction error',
  transactionInProgressError: 'Existing transaction is in progress',
  transactionNotFoundError: 'Transaction not found',
  trashSymbol: 'Trash Symbol',
  trashSymbolDescription: 'No paying symbol. It can land on reels in free spins.',
  trashSymbolWithoutBonusDescription: 'No paying symbol. It can only land on reels in base game.',
  turbospin: 'Turbo spin',
  type: 'Type',
  unverifiedPlayerEmail: 'Email is not verified, try in demo mode',
  wild: 'wild',
  wildFeature: 'Wild feature',
  wildFeatureDescription: 'Wild substitutes every symbol except Bonus symbols. Wilds can show up on all reels, linking them would make big wins.',
  wildStickySymbol: 'Wild Sticky Symbol',
  wildSymbol: 'Wild Symbol',
  wildSymbolPaytableDescription: 'Wild symbol replace any symbol except Bonus.',
  wildSymbolsTitle: 'Wild symbols',
  win: 'Win',
  winLimit: 'Win limit',
  winningBetLines: 'Winning bet lines',
  winSymbolsTitle: 'Win symbols',
  winUpLabel: 'Win up to',
  writeConflict: 'An error happened. Close this message to continue.',
  xOrMoreTriggerFreeSpinsLabel: '${hits} or more trigger free spins bonus game',
  xOrMoreTriggerRespinLabel: '${hits} or more trigger respin bonus game',
  youWon: 'You won',
  // Cherry Blast
  cherryBlastBonusSymbolDescription: 'Land at least three bonus symbols to trigger the bonus game. The more bonus symbols you land, the more free spins you get',
  cherryBlastCashLinkDesctiption: 'Land at least four cash or jackpot symbols on the reels to trigger the Cash Link Bonus game. Three respins are granted and when you land either cash, jackpot or multiplier symbol, number of respins resets back to three. Within the Cash Link Bonus game, there are three distinct jackpots with each jackpot offering its own unique prize. This bonus game includes a global multiplier, x2, which activates when all spots on the reels are populated. Global multiplier applies to all jackpots which landed on the reel as well. The collect phase continues until you get three non-winning spins in a row.',
  cherryBlastFreeSpinsDescription: 'Score big with free spins! Land at least three bonus symbols to activate the bonus game with Sticky wild feature for every spin! Land three bonus symbols to activate Sticky Spin delight with 10 free spins with Sticky wild feature offering chances for enormous wins. Land four bonus symbols to activate the Mega Sticky Spins with 15 free spins with Sticky wild feature offering enhanced opportunities for substantial wins. Besides more free spins, extra wilds have been added to the reels.',
  cherryBlastGameInfo: '${name} is a 4x3 classic slot game which offers an engaging experience with an RTP of ${rtp}%. Featuring a Wild and Sticky wild features, it adds an extra layer of excitement to the gameplay. Landing at least three bonus symbols triggers the free spins bonus game. During the free spins wild symbols get sticky. Additionally, four cash or jackpots symbols on the reels trigger the Cash Link Bonus game which features jackpots, enhancing the excitement and potential rewards for players.',
  cherryBlastJackpotSymbolsDescription: 'Appearance of the following jackpot symbols during the Cash Link Bonus Game triggers the corresponding jackpot.',
  cherryBlastMultiplierSymbolDescription: 'It multiplies all cash symbols with the multiplier 2 during the Cash Link Bonus game when it lands.',
  cherryBlastOnboardStickyCherryDescription: 'Sticky golden cherry',
  cherryBlastStoreDescription: 'To buy the bonus rounds, you have to wager at least 100x your regular bet. Buy the Sticky Spin delight to activate 10 free rounds with sticky wilds. The theoretical RTP of the feature is equal to ${rtp1}%. Buy the Mega Sticky Spins to activate 15 free rounds with sticky wilds. The theoretical RTP of the feature is equal to ${rtp2}%.Buy the Cherry Harvesting to activate Cash Link Bonus game which gives you an exciting opportunity to win the jackpots. The theoretical RTP of the feature is equal to ${rtp3}%.',
  // Fishy Guy
  fishyGuyBonusFeaturesDescription: 'A player has to land at least three bonus symbols to trigger the bonus game. Each bonus symbol has the number of free spins it grants to a player. The total number of free spins equals the sum of all free spins from bonus symbols. Bonus symbols can’t land on the first and last reel. During the free rounds, instead of extra rows, there are 5 possible levels. ',
  fishyGuyBonusSymbolDescription: 'Land at least three bonus symbols to trigger a bonus game. The total number of free spins equals the sum of all free spins from bonus symbols.',
  fishyGuyFifthLevelDescription: 'To reach the last level, players have to collect ten upgrade symbols. Ten extra spins are granted to a player, and all wins are multiplied by ten now. Also, only special fish symbols can land on the reels now.',
  fishyGuyFirstLevelDescription: 'Players are collecting symbols that upgrade their current level. Two upgrade symbols are needed to upgrade the fisherman once. Two extra spins are granted to a player, and the fisherman multiplies each win with two.',
  fishyGuyFishermanSymbolDescription: 'Collects all cash symbols except those placed in the extra row.',
  fishyGuyFishSymbolsDescription: 'Shows the possible cash amount a player can win. Multipliers on the cash symbols increase progressively. They take a random value of ${multiplier1}x your bet to ${multiplier2}x your bet on the fifth level. RIP fish gives you no multiplier. All levels are unlocked in the bonus game, and the maximum multiplier is an astonishing ${multiplier3}x your bet.',
  fishyGuyFourthLevelDescription: 'Ten more upgrade symbols are needed to upgrade the fisherman to the fourth level. Five extra spins are granted to a player, and the fisherman multiplies each win with seven. Now, when the fisherman is strong enough, the special fish symbols are unlocked and they can land on the reels.',
  fishyGuyGameInfo: '"Fishy Guy" is a 5-reel and 6-row slot machine with the respin feature. This means that each time a win happens, winning symbols disappear and symbols from the top row and new symbols take their spots. The top row is dedicated to the special symbols. These symbols show the possible amount a player can win. If a winning combination happens, but some of the symbols in the top row don’t fall in the grid, their value increases for the next respin. The game RTP is equal to ${rtp}%.',
  fishyGuyHowToPlayDescription: 'Select a bet size, then press the Spin button to begin. To use autoplay, press the Autoplay button and select the desired number of rounds. You can stop autoplay mode anytime by clicking the stop button. You can control the spin speed by selecting one of the predefined spin options:\n- Normal spin: This is the default mode.\n- Lighting spin: In the first spin symbols just fall off on the reel, without any lazy animations. You can activate this option in the settings.\n- Lighting mode: To activate this mode press the spin button for 1 second. The lighting spin is activated, and an autoplay with an infinite number of spins is started. You can always stop this mode by clicking the stop button.',
  fishyGuyOnboardText1: 'CATCH A BIG FISH AND WIN BIG',
  fishyGuyOnboardText2: 'NON-EXPLODING WILDS\nWITH MULTIPLIERS',
  fishyGuyOnboardText3: '3 BONUS SYMBOLS TRIGGER\nEXPLODING SPINS',
  fishyGuySecondLevelDescription: 'Three more symbols upgrade the fisherman to the second level. Now three extra spins are granted to the player, and the fisherman multiplies each win with three.',
  fishyGuySpecialFishSymbolDescription: 'These special symbols can be unlocked during the bonus game. Once players upgrade their fisherman symbol to level four in the bonus game, special symbols can land on the reels. If the fisherman level upgrades to the fifth level, only special symbols can land on the reels.',
  fishyGuyStoreDescription: '- Each option can only be activated in the main game.\n- To buy the bonus round you have to wager ${bonusBuyPrice}x your regular bet. The theoretical RTP of the feature is equal to ${bonusBuyRtp}%.\n- Buy the Fisherman spin to activate the collect feature in the next spin. At least one fisherman lands on the reels, and one winning combination happens. The theoretical RTP of the feature is equal to ${collectBuyRtp}%.',
  fishyGuySuperFishermanSymbolDescription: 'Collects all cash symbols from a screen.',
  fishyGuyThirdLevelDescription: 'To upgrade the fisherman to the third level five upgrade symbols are needed. Five extra spins are granted to a player, and the fisherman multiplies each win with five.',
  fishyGuyUpgradeLevelSymbolDescription: 'Unlocks new levels in the bonus game by collecting it.',
  fishyGuyWildMultiplierSymbolDescription: 'The wild symbol that multiplies the won amount, but no cash values.',
  fishyGuyWildSymbolDescription: 'The wild symbol substitutes for all symbols on the reels, except bonus and collect symbols.',
  // Gold of Egypt
  goldOfEgyptBuyDescription: 'You can buy 20 non-risk spins, and dead symbols can’t land during this mode. It increases your chance of claiming the top prize. The price depends on the top prize amount you’ve collected. The higher the top prize amount, the higher the price.',
  goldOfEgyptBuyDisabledTooltip: 'Collect cashpot to buy the bonus.',
  goldOfEgyptDeadFirstSymbolDescription: 'First dead symbol decreases each tower’s level by one, but it doesn’t reset the collected cashpot amount.',
  goldOfEgyptDeadSecondSymbolDescription: 'Second dead symbol resets all towers and the cashpot to zero.',
  goldOfEgyptDeadSymbolsTitle: 'Dead symbols',
  goldOfEgyptDialogBetAmountDisabledTooltip: 'Collect cashpot to change bet.',
  goldOfEgyptDirectWinDescription: 'Every time you land a symbol whose tower is full, you get paid directly the highest symbol’s cash amount. In case you land the wild symbol two while one of the towers is full, you will be paid double the amount of the highest symbol’s cash value from that tower.',
  goldOfEgyptGameInfoDescription: 'The game features a simple layout with a single reel and one stop, designed for straightforward gameplay. Above the reel, the paytable is prominently displayed, providing clear visibility of potential winnings. In addition to this, the cashpot shows the cumulative winnings from each level of the tower, giving players a comprehensive view of their gains.\n\nIn both demo and real modes, your current balance is consistently visible in the balance area. The bet amount, which can be adjusted only when the cashpot is empty, is displayed in the bet area. Players have the option to select from predefined bet amounts, all of which are listed in real currency. Wins are accumulated in the cashpot, except for the Direct Win and Top Prize, which are directly credited to your balance if you win them. You can transfer your wins from the cashpot to your balance at any time by pressing the collect button.\n\nFor those interested in a more automated experience, the autoplay options panel allows you to select the number of spins. Once you initiate the autoplay session by pressing the start button, you can halt the spins at any moment using the stop autoplay button. The Win Limit feature displays the autoplay cashpot limit, enabling you to set a target amount at which the autoplay will stop.\n\nIn the event of an internet disconnection during gameplay, simply log back into the website and reopen the game. This will allow you to resume play from the exact point where the game was interrupted. Please note, any malfunction during gameplay voids all bets, ensuring fairness and compliance with gaming regulations.',
  goldOfEgyptIntroSlide1FirstLine: 'Collect multipliers for',
  goldOfEgyptIntroSlide1SecondLine: 'Reward',
  goldOfEgyptIntroSlide2FirstLine: 'Level up',
  goldOfEgyptIntroSlide2SecondLine: 'with',
  goldOfEgyptIntroSlide3FirstLine: 'Watch out for',
  goldOfEgyptIntroSlide3SecondLine: 'Traps',
  goldOfEgyptLandingBasicSymbolDescription: 'Every time the symbol lands on the screen, it highlights a cash level on its tower. Only the last symbol level is calculated into the cashpot amount.',
  goldOfEgyptLandingBasicSymbolTitle: 'Landing a basic symbol',
  goldOfEgyptPlayerGetsPaidDescription: 'All cash is collected in the cashpot. You can collect the cash placed in the cashpot anytime, or keep playing to win the top prize.',
  goldOfEgyptPlayerGetsPaidTitle: 'When a player gets paid',
  goldOfEgyptRoundsInRowDescription: 'The maximum number of bets allowed before emptying the pot in a single session is 1000. Whenever you withdraw money from the pot, the count resets automatically.',
  goldOfEgyptRtpDescription: 'The game RTP is ${RTP}, and it depends on user strategy.',
  goldOfEgyptRtpTitle: 'Game RTP',
  goldOfEgyptSpinsWithoutTrapsLabel: 'Spins without traps',
  goldOfEgyptTopPrizeDescription: 'Landing the Top Up symbol will increase the value of the top prize by certain amount. When all towers are highlighted you get the top prize amount.',
  goldOfEgyptTopPrizeTitle: 'Top prize',
  goldOfEgyptTowersDescription: 'Each of the two towers corresponds to a symbol. Blue symbol fills left, and purple fills the right tower.',
  goldOfEgyptTrashSymbolDescription: 'No paying symbol.',
  goldOfEgyptTrashSymbolTitle: 'Trash symbol',
  goldOfEgyptWildFirstSymbolDescription: 'First wild symbol highlights one level on each tower when it lands on the reel. The cashpot amount increases for those levels.',
  goldOfEgyptWildSecondSymbolDescription: 'Second wild symbol highlights two levels on each tower when it lands on the reel. The cashpot amount increases for those levels.',
  // Sugar Blast
  sugarBlastAboutTheGame: 'Sugar Blast is a 6-reel and 5-row slot machine with the tumble feature. This means that each time a win happens, winning symbols disappear and new symbols take their spots. This continues until no more winning combinations appear on the screen. Symbols pay anywhere on the reels. The theoretical RTP of the game is ${rtp}%',
  sugarBlastBonusGameDescription: 'Land at least four bonus symbols to trigger the bonus game with 6 free spins. During the bonus game cascade multipliers don\'t reset between free spins, meaning that they progressively grow as the tumbling wins happen. Lollipop multipliers and wild symbols are added to the reels during the free rounds bonus game.',
  sugarBlastBonusSymbolDescription: 'Land at least four bonus symbols to trigger the bonus game. The more bonus symbols you land, the more lollipop multipliers are added on the reels.',
  sugarBlastCascadeMultiplierDescription: 'The top row is dedicated to the cascade multipliers. Multipliers are generated before each spin, the more tumbling wins happen, the bigger the multiplier. Each time a win occurs, the current multiplier is increased by the following multiplier for the next respin. During the bonus game, the multiplier doesn\'t reset.',
  sugarBlastExtraMultiplierSymbolDescription: 'The lollipop symbol can land on the reels. Whenever it lands, it carries a random multiplier value that is added to the current cascade multiplier. The multiplier on the lollipop goes up to ${extraMultiplierMax}.',
  sugarBlastOnboardInfo1: '${minBonusSymbols} OR MORE\nTRIGGER\nBONUS GAME',
  sugarBlastOnboardInfo2: 'EVERY WIN\nINCREASES\nTHE MULTIPLIER',
  sugarBlastStoreDescription: 'Each option can only be activated in the main game.\nTo buy Sugar Rush you have to wager ${extraMultiplierBuyPrice} times your regular bet. The theoretical RTP of the feature is equal to ${extraMultiplierBuyRtp}%.\nWagering ${nearMissBonusBuyPrice} times your regular bet gives you a chance of 50% to trigger the bonus game. The theoretical RTP of the feature is equal to ${nearMissBonusBuyRtp}%.\nTo buy the Sweet Blast you have to wager ${bonusBuyPrice} times your regular bet. The theoretical RTP of the feature is equal to ${bonusBuyRtp}%.\nTo buy the Sweet Blast with more multipliers you have to wager ${bonusBuyPrice2} times your regular bet. The theoretical RTP of the feature is equal to ${bonusBuyRtp2}%.\nTo buy the Sweet Blast with even more multipliers you have to wager ${bonusBuyPrice3} times your regular bet. The theoretical RTP of the feature is equal to ${bonusBuyRtp3}%.',
  sugarBlastWildSymbolDescription: 'Wild substitutes every symbol except Bonus symbols. The wild symbol vanishes alongside basic symbols in winning hits.',
  // Toluca Fortune
  tolucaFortuneAddSymbolDesc: 'The add symbol increases all cash values with the amount shown on it. The value of Add symbol can go up to ${maxValue}.',
  tolucaFortuneCashLinkBonusIntroLabel: 'Fill all positions for',
  tolucaFortuneCashLinkDesc: 'Land at least five cash symbols to trigger the Cash Link Bonus game. Three respins are granted and when you land either cash, jackpot, multiplier or add symbol, number of respins resets back to three. Within the Cash Link Bonus Game, there are three distinct jackpots with each jackpot offering its own unique prize. This bonus game includes a Progress Bar, which activates when all spots on the reels are populated by cash symbols. Populate the Progress Bar to multiply your winnings up to x${finalMultiplier}. The collect phase continues until you get three non-winning spins in a row.',
  tolucaFortuneCashLinkOnboardDesc: '5 or more triggers respins game',
  tolucaFortuneCashSymbolDesc: 'Cash symbol provides a bet multiplier worth ${minValue}x - ${maxValue}x your stake.',
  tolucaFortuneExpandingWildFeatureDesc: 'Like every Wild symbol, substitutes every symbol except Bonus symbols. Expanding Wild expands over the entire reel hiding the other symbols which were on that reel.',
  tolucaFortuneFreeSpinsDesc: 'Score big with free spins! Land three bonus symbols to activate the Sticky Aztec Spins with 10 free spins which feature Sticky Wild symbols with multipliers offering chances for big wins. Land four bonus symbols to activate the Fortune Expanding Spins with 10 free spins which feature Expanding Sticky wilds with multipliers offering enhanced opportunities for substantial wins.',
  tolucaFortunefreeSpinsOnboardDesc: '3 or more triggers free spins',
  tolucaFortuneGameInfo: 'Toluca Fortune is a ${reels}-reel, ${rows}-row slot game featuring wild, Sticky wild, Expanding wild and Expanding Sticky wild features. With an RTP of ${rtp}%, it promises exciting gameplay and substantial rewards. Landing at least three Bonus symbols triggers the free spins bonus game. The bonus game can feature Sticky wilds and Expanding Sticky wilds. Additionally, at least five Cash symbols triggers the Cash Link Bonus game which features jackpots, enhancing the excitement and potential rewards for players.',
  tolucaFortuneHowToPlayDesc: 'Select a bet size, then press the Spin button to begin. To use autoplay, press the Autoplay button and select the desired number of rounds. You can stop autoplay mode anytime by clicking the stop button. You can control the spin speed by selecting one of the predefined spin options:\n- Normal spin: This is the default mode.\n- Lighting spin: In a spin symbols just fall off on the reel, without any lazy animations. You can activate this option in the settings.\n- Lighting mode: To activate this mode press the spin button for 1 second. The lighting spin is activated, and an autoplay with an infinite number of spins is started. You can always stop this mode by clicking the stop button.',
  tolucaFortuneJackpotSymbolsDescription: 'Land one of the following jackpot symbols three times during the Cash Link Bonus Game and trigger the corresponding jackpot. A single jackpot can be won more than once during the feature.',
  tolucaFortuneMessageBarCashLinkPostMessage: 'triggers cash link',
  tolucaFortuneMessageBarFreeSpinsPostMessage: 'triggers free spins',
  tolucaFortuneMultiplierSymbolDesc: 'The multiplier symbol multiplies each multiplier which landed on the reels. The value of Multiplier symbol can go up to ${maxValue}.',
  tolucaFortuneStickyExpandingWildBonusIntroLabel: 'Free spins with sticky expanding wilds',
  tolucaFortuneStickyExpandingWildSymbolDesc: 'Expanding sticky wild expands over the entire reel hiding the other symbols which were on that reel and when it lands on the reels, it becomes sticky and doesn\'t change the position until the free spins are finished.',
  tolucaFortuneStickyWildBonusIntroLabel: 'Free spins with sticky wilds',
  tolucaFortuneStickyWildSymbolDesc: 'When wild symbol lands on the reels, it becomes sticky and doesn\'t change the position until the free spins are finished. The multiplier on the symbol can go up to x${maxValue}.',
  tolucaFortuneStoreDesc: 'To buy the bonus rounds, you have to wager at least ${minWager}x your regular bet.\nBuy the Sticky Aztec Spins to activate 10 free rounds with sticky wilds. The theoretical RTP of the feature is equal to ${rtp1}%.\nBuy the Fortune Expanding Spins to activate 10 free rounds with sticky expanding wilds. The theoretical RTP of the feature is equal to ${rtp2}%.\nBuy the Toluca Fortune Blast to activate Cash Link Bonus game which gives you an exciting opportunity to win the jackpots. The theoretical RTP of the feature is equal to ${rtp3}%.',
  tolucaFortuneWildFeatureDesc: 'Wild substitutes every symbol except Bonus symbols. Wilds can show up on all reels, linking them would make big wins.',
};
