import { endsWith } from 'lodash';
import { Howl as Sound } from 'howler';
import { utils } from '../pixi';

const audioParser = {
  extension: {
    type: 'load-parser',
    priority: 'high',
  },
  audioConfigurations: [
    {
      extension: 'mp3',
      isHTML5Audio: utils.isMobile.apple.device,
    },
    {
      extension: 'aac',
      isHTML5Audio: false,
    },
  ],
  test(url) {
    return this.audioConfigurations.some((config) => endsWith(url, config.extension));
  },
  useHTML5Audio(url) {
    return this.audioConfigurations.some((config) => endsWith(url, config.extension) && config.isHTML5Audio);
  },
  async load(url, asset) {
    const sound = new Sound({
      src: [url],
      preload: true,
      html5: this.useHTML5Audio(url),
    });

    return new Promise((resolve, reject) => {
      sound.once('load', () => {
        resolve(sound);

        if (asset.data && asset.data.loaded) {
          asset.data.loaded(undefined, sound);
        }
      });

      sound.once('loaderror', (error) => {
        reject(error);

        if (asset.data && asset.data.loaded) {
          asset.data.loaded(error, sound);
        }
      });
    });
  },
};

export {
  audioParser,
};
